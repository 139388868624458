.main {
    height: 70vh;
    
    :global(.esri-search) {
        width: 40vw;
        min-width: 15rem;
        
        * {
            width: 100%;
        }
    }
}